import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"

export default class ContentFile extends Component {
  render() {

	return <StaticQuery
			query={graphql`
			query contentResource {
				allFile(filter: {url: {regex: "^https.*/g"}, ext: {eq: ".pdf"}}) {
					nodes {
					  url
					  publicURL
					}
				}
			}
		`}
		render={data => {
			const fileSrc = new URL(`${process.env.API_URL_PROTOCOL}://${process.env.API_URL}` + this.props.url).toString();
			const fileNode = data.allFile.nodes.find(file => (file.url === fileSrc));
			if (fileNode) {
				return <a href={fileNode.publicURL} target="_blank" rel="noreferrer">{this.props.name}</a>
			}
		}}	/>
	}
}
